<template>
  <div class="models-room-select">
    <select name="room" :class="['models-location-form-select', model.idUserCollection && 'disabled']">
      <option value="">Seleccionar habitación</option>
      <option v-for="(room, index) in rooms" :key="index + room.roomName" :value="room.roomName" :selected="room.roomName === model.room">
        {{ room.roomName }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ["model", "location", "workShift"],
  methods: {
    getModels: async function () {
      if (!this.$store.state.models) {
        this.loading = true;
        try {
          this.$store.state.models = "loading";
          const host = `${process.env.VUE_APP_API_HOST}admin/users?role=model&limit=300`;
          const { data } = await this.$api.get(`${host}`);
          this.$store.state.models = data.users;
        } catch (error) {
          this.$swal.fire({ title: "Error", text: error });
        }
        this.loading = false;
      }
    },
    getRooms: async function () {
      const { api: host } = this.$global.getApp("autologin");
      if (!this.$store.state.rooms) {
        this.loading = true;
        this.$store.state.rooms = "loading";
        try {
          const { data } = await this.$api.get(`${host}/rooms`);
          this.$store.state.rooms = data.rooms;
          this.filterRooms(data.rooms);
        } catch (error) {
          this.$swal.fire({ title: "Error", text: error });
        }
        this.loading = false;
      }
    },
    filterRooms: function (rooms) {
      const filteredRooms = {};
      rooms?.map((room) => {
        filteredRooms[room.officeName] =
          typeof filteredRooms[room.officeName] != "undefined" && filteredRooms[room.officeName] instanceof Array
            ? filteredRooms[room.officeName]
            : [];
        filteredRooms[room.officeName].push(room);
      });
      this.$store.state.rooms = filteredRooms;
    },
  },
  beforeMount: async function () {
    await this.getModels();
    this.getRooms();
  },
  computed: {
    rooms: function () {
      const models = this.$store.state.allModels;
      const rooms = this.$store?.state?.rooms && this.$store.state.rooms[this?.location] ? this.$store.state.rooms[this?.location] : [];

      const roomSatelite = [
        {
          _id: "Satelite",
          roomName: "Satelite",
          officeName: "Satelite",
          cityName: "Global",
        },
      ];

      return this.location === "Satelite"
        ? roomSatelite
        : rooms.filter((room) => !models.some((model) => model.workShift.name === this.model.workShift.name && model.room === room.roomName));
    },
  },
};
</script>

<style lang="scss">
.models-location-form-select {
  margin: $mpadding/1.7 0 0 0;
  .disabled {
    pointer-events: none;
    opacity: 0.8;
    color: gray;
  }
}
</style>
